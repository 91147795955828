<template>
    <a-card :bordered="false">

      <div slot="extra">
        <a-button v-if="flagBack" type="primary" icon="plus" @click="goBack()" style="margin-right:5px;">返回</a-button>
        <a-radio-group v-model="menuSelect" :defaultValue="menuSelect">
          <a-radio-button
            v-for="item in menuList"
            :value="item.value"
            :key="item.id"
            @click="menuChoose(1,item.value)"
            >{{ item.description }}</a-radio-button>
          <a-radio-button
            :value="0"
            :key="0"
            @click="menuChoose(2,null)"
          >非常规指标</a-radio-button>
        </a-radio-group>
        <a-button type="primary" icon="plus" @click="goEdit(0)" style="margin-left:5px;">新建</a-button>
      </div>
      <s-table
        ref="table"
        size="default"
        rowKey="id"
        :columns="columns"
        :data="loadData"
        :alert="false"
        showPagination="true"
      >

        <span slot="type" slot-scope="text">
          <a-badge :status="text | typeTypeFilter" :text="text | typeFilter" />
        </span>
        <span slot="limit" slot-scope="text">
          <ellipsis :length="14" tooltip>{{ text }}</ellipsis>
        </span>

        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="goEdit(record.id)">编辑</a>
            <a-divider type="vertical" />
            <a @click="goDel(record.id)">删除</a>
          </template>
        </span>
      </s-table>
    </a-card>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import { delItemInfo, getItemPage } from '@/api/quality/item'
import { getMenuList } from '@/api/basic/common'

const columns = [
  {
    title: '序号',
    width: '8%',
    dataIndex: 'index'
  },
  {
    title: '指标',
    dataIndex: 'descChinese'
  },
  {
    title: '指标单位',
    dataIndex: 'unit',
    sorter: true
  },
  {
    title: '指标类型',
    dataIndex: 'type',
    scopedSlots: { customRender: 'type' }
  },
  {
    title: '指标限值',
    dataIndex: 'limitValue',
    scopedSlots: { customRender: 'limitValue' }
  },
  {
    title: '操作',
    width: '15%',
    dataIndex: 'action',
    align: 'center',
    scopedSlots: { customRender: 'action' }
  }
]

var typeMap = {}

export default {
  name: 'TableList',
  components: {
    STable,
    Ellipsis
  },
  data () {
    this.columns = columns
    return {
      // 查询参数
      menuParam: {
        menu: '指标类型'
      },
      listParam: {
        category: 1,
        type: 1
      },
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({}, parameter, this.listParam)
        // console.log('loadData request parameters:', requestParameters)
        return getItemPage(requestParameters)
          .then(res => {
            
            for (var i = 0;i<res.data.list.length;i++) {
              res.data.list[i].index = ((parameter.pageNumber - 1)* parameter.pageSize) + i + 1
            }

            var data = {
              data: res.data.list,
              pageNo: parameter.pageNumber,
              pageSize: parameter.pageSize,
              totalCount: res.data.total,
              totalPage: parseInt(res.data.total / parameter.pageSize) + 1
            }
            return data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],
      // 接口返回数据
      menuList: [],
      menuSelect: '1',
      flagBack: 0
    }
  },
  filters: {
    typeFilter (type) {
      return typeMap[type].text
    },
    typeTypeFilter (type) {
      return typeMap[type].status
    }
  },
  created () {

    console.log('==============================')
    console.log(this.$store)

    this.flagBack = this.$route.query.id;
    this.getMenu(this.menuParam)
  },
  computed: {
  },
  methods: {
    // 接口请求
    getMenu (p) {
      getMenuList(p).then(response => {
        this.menuList = response.data.list

        //优化，修正如果菜单返回类型有变化，显示过滤器无法自动适应的问题
        let menus = this.menuList;
        for (let i in menus) {
          let result = menus[i];
          typeMap[result.value]={
            status: 'success',
            text: result.description
          }
        }

        this.menuSelect = response.data.list[0].id
        this.listParam.type = response.data.list[0].value
        this.loadData(this.listParam)
        this.$refs.table.refresh(true)
      })
    },
    // 操作方法
    menuChoose (c, v) {
      this.listParam.category = c
      this.listParam.type = v
      this.loadData(this.listParam)
      this.$refs.table.refresh(true)
    },
    goEdit (id) {
      this.$router.push({
        path: '/test/item/edit',
        query: {
          id: id
        }
      })
    },
    goBack () {
      this.$router.back()
    },
    goDel (id) {
      var self = this
      this.$confirm({
        title: '确认删除',
        content: `是否确认删除此检测项？`,
        cancelText: '取消',
        okText: '确定',
        okType: 'danger',
        onOk () {
          return new Promise((resolve, reject) => {
            delItemInfo({ id: id }).then(response => {
              self.$message.info(`数据删除成功`)
              self.loadData(self.listParam)
              self.$refs.table.refresh(true)
            }).catch((e) => {
              self.$message.error(`数据删除失败,错误原因:${response.msg}`)
            }).finally(() => {
              resolve()
            })
          })
        }
      })
    }
  }
}
</script>
